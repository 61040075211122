// composables/useFormattable.ts
import { computed } from 'vue';
import * as Util from '../utils';
import { useUsersStore } from '@/stores/users';
import { useStoreStore } from '@/stores/store';
import { useStaffsStore } from '@/stores/staffs';
import { useDisplay } from 'vuetify';
import { Permission, storeType } from '../appCode';
import { CommonSettings, CustmizedContractId, CustmizedContractIds } from '../common-settings';

export function useFormattable() {
  const screen = useDisplay();
  const userStore = useUsersStore();
  const storeStore = useStoreStore();
  const staffsStore = useStaffsStore();

  // Computed properties
  const settings = computed(() => userStore.getSettings);
  const accountSetting = computed(() => userStore.getAccountSetting);
  const storeList = computed(() => storeStore.storeList);

  const is2ndGenData = computed(() => accountSetting.value.is2ndGenData);
  const contractId = computed(() => userStore.getUser?.uid ?? accountSetting.value?.params?.contractId ?? '');
  const storeId = computed(() => userStore.getStoreId ?? '');
  const storeName = computed(() => userStore.getCurrentStore?.name ?? '');
  const staffId = computed(() => userStore.getStaff);

  const currentStaffName = computed(() => {
    const staffsList = staffsStore.staffsList;
    const staff = staffsList.find((val) => val.id === staffId.value);
    return staff?.params.name ?? '';
  });

  const isEatInStore = computed(() => accountSetting.value.isEatinStore);
  const isTakeoutStore = computed(() => accountSetting.value.isTakeoutStore);
  const isBothStore = computed(() => accountSetting.value.isBothStore);
  const isWaitingStore = computed(() => accountSetting.value.isWaitingStore);
  const isEcStore = computed(() => accountSetting.value.isEcStore);
  const isMembershipStore = computed(() => accountSetting.value.isMembershipStore);
  const isUseStampCard = computed(() => accountSetting.value.params.membershipInfo?.useStampCard ?? false);
  const isEatInPlus = computed(() => accountSetting.value.isEatInPlus);

  const environment = computed(() => process.env.VUE_APP_MODE ?? '');
  const isProduction = computed(() => environment.value === 'production');
  const isStaging = computed(() => environment.value === 'staging');
  const isLocal = computed(() => environment.value === 'local');

  const isAdminUser = computed(() => userStore.getPermission === Permission.admin);
  const isNormalUser = computed(() => userStore.getPermission === Permission.normal);
  const isMemberUser = computed(() => userStore.getPermission === Permission.member);

  const isHeadquarter = computed(() => {
    return userStore.getStoreType === storeType.headquarters || userStore.getStoreType === '';
  });

  const isNormalStore = computed(() => {
    return userStore.getStoreType === storeType.store || userStore.getStoreType === '';
  });

  const isHeadquarterLogin = computed(() => {
    return Boolean(sessionStorage.getItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_STORE_ID));
  });

  const headquarterPassword = computed(() => {
    return sessionStorage.getItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_PASSWORD) ?? '';
  });

  const isHeadquarterOf2ndGen = computed(() => is2ndGenData.value && isHeadquarter.value);

  // Utility methods
  const numberWithComma = (num: number): string => num?.toLocaleString() ?? '0';
  const numberWithCommaAndNoDecimal = (num: number): string =>
    num.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });

  const timeByColon = (date: Date | null | undefined): string => (date ? Util.timeFormatByColon(date) : '--:--');
  const dateTimeBySlash = (date: Date | null): string => (date ? Util.dateTimeFormatBySlash(date) : '----/--/-- --:--');
  const dateTimeByHyphen = (date: Date | null): string => (date ? Util.dateTimeFormatByHyphen(date) : '');

  const isSpecifiedContract = (contractName: keyof CustmizedContractId): boolean =>
    CustmizedContractIds[contractName] === contractId.value;

  return {
    screen,
    settings,
    accountSetting,
    storeList,
    is2ndGenData,
    contractId,
    storeId,
    storeName,
    staffId,
    currentStaffName,
    isEatInStore,
    isTakeoutStore,
    isBothStore,
    isWaitingStore,
    isEcStore,
    isMembershipStore,
    isUseStampCard,
    isEatInPlus,
    environment,
    isProduction,
    isStaging,
    isLocal,
    isAdminUser,
    isNormalUser,
    isMemberUser,
    isHeadquarter,
    isNormalStore,
    isHeadquarterLogin,
    headquarterPassword,
    isHeadquarterOf2ndGen,
    numberWithComma,
    numberWithCommaAndNoDecimal,
    timeByColon,
    dateTimeBySlash,
    dateTimeByHyphen,
    isSpecifiedContract,
  };
}

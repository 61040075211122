<template>
  <v-app>
    <!-- ログイン済みの場合にメニューを表示 -->
    <menu-bar
      v-if="alreadyLogin && alreadyStoreLogin"
      :pageTitle="pageTitle"
      :pageOrder="pageOrder"
      :appVersion="appVersion"
      @onPushItem="pageTransition"
      @onPushLogout="onPushLogout"
      @error="errorCapture"
    />
    <v-main v-if="alreadyLogin" :style="{ background: $vuetify.theme.themes.light.variables.background }">
      <router-view @error="errorCapture"></router-view>
    </v-main>
    <router-view v-if="!alreadyLogin" @error="errorCapture"></router-view>
    <!-- Dialog -->
    <error-dialog ref="errorDialog" />
    <loading-dialog ref="loadingDialog" :display="isLoading" :message="loadingMessage" />
    <password-dialog
      ref="passwordDialog"
      :message="'この画面へ遷移するためにはパスワードの入力が必要です。'"
      @onPushEnterBtn="onPushPasswordDialogEnterBtn"
    />
    <confirmation-dialog
      ref="logoutConfirmationDialog"
      :title="'ログアウト'"
      :message="'ログアウトしてもよろしいですか?'"
      @onPushEnterBtn="onPushLogOutConfirmationDialogEnterBtn"
    />
  </v-app>
</template>
<script lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, useTemplateRef } from 'vue';
import { useFormattable } from '@/common/composables/useFormattable';
import { useAppStore } from '@/stores/app';
import { EnvConfig } from '@/common/env-config';
import { useUsersStore } from '@/stores/users';
import * as Pages from '@/router/index';
import MenuBar from '@/components/organisms/MenuBar.vue';
import ErrorDialog from '@/components/molecules/ErrorDialog.vue';
import PasswordDialog, { PasswordDialogEnterBtnParams } from '@/components/molecules/PasswordDialog.vue';
import LoadingDialog from '@/components/molecules/LoadingDialog.vue';
import ConfirmationDialog from '@/components/molecules/ConfirmationDialog.vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'App',
  components: {
    'menu-bar': MenuBar,
    'error-dialog': ErrorDialog,
    'password-dialog': PasswordDialog,
    'loading-dialog': LoadingDialog,
    'confirmation-dialog': ConfirmationDialog,
  },
  setup() {
    const { accountSetting, contractId, storeId } = useFormattable();

    const appStore = useAppStore();
    const userStore = useUsersStore();
    const router = useRouter();
    const route = useRoute();

    const pageTitle = ref('');
    const errorDialog = useTemplateRef<InstanceType<typeof ErrorDialog>>('errorDialog');
    const passwordDialog = useTemplateRef<InstanceType<typeof PasswordDialog>>('passwordDialog');
    const logoutConfirmationDialog =
      useTemplateRef<InstanceType<typeof ConfirmationDialog>>('logoutConfirmationDialog');

    const pageOrder = Pages.pageOrder;

    const alreadyLogin = computed(() => userStore.getLogin);
    const alreadyStoreLogin = computed(() => userStore.getStoreLogin);
    const appVersion = EnvConfig.app.APP_VERSION || '';
    const isLoading = computed(() => appStore.getIsLoading);
    const loadingMessage = computed(() => appStore.getLoadingMessage);

    watch(
      () => route.name, // 監視する対象
      (name) => {
        pageTitle.value = (name as string) || '';
      }
    );

    const pageTransition = async (page) => {
      if (page.requireAuthorization && !accountSetting.value.passwordLockDisabled) {
        passwordDialog.value?.open(page.route);
      } else if (page.openOtherWindow) {
        window.open(`${page.route}?contractId=${contractId.value}&storeId=${storeId.value}`, '_blank');
      } else if (page.route !== window.location.pathname) {
        pageTitle.value = page.name; // pageTitleを更新
        await router.push(page.route);
      }
    };

    const onPushPasswordDialogEnterBtn = async (params: PasswordDialogEnterBtnParams) => {
      try {
        appStore.startLoading();
        await userStore.reauthentication(params);
        await router.push(params.data);
      } catch (error) {
        errorCapture(error);
      } finally {
        appStore.stopLoading();
      }
    };

    const onPushLogout = () => {
      logoutConfirmationDialog.value?.open();
    };

    const onPushLogOutConfirmationDialogEnterBtn = async () => {
      try {
        appStore.startLoading();
        await userStore.logout();
        await router.push('/login');
      } catch (error) {
        errorCapture(error);
      } finally {
        appStore.stopLoading();
      }
    };

    const errorCapture = (error) => {
      console.error(error);
      errorDialog.value?.open({
        message: error.message || 'エラーが発生しました。',
      });
    };

    const disablePinch = (event: TouchEvent) => {
      if (event.touches.length >= 2) {
        event.preventDefault();
      }
    };

    onMounted(() => {
      document.addEventListener('touchstart', disablePinch, { passive: false });

      setInterval(() => {
        if (!navigator.onLine) {
          errorDialog.value?.open({
            message: 'インターネットに接続されていません。',
          });
        }
      }, 10000);
    });

    onUnmounted(() => {
      document.removeEventListener('touchstart', disablePinch);
    });

    return {
      appVersion,
      pageTitle,
      pageOrder,
      alreadyLogin,
      alreadyStoreLogin,
      isLoading,
      loadingMessage,
      errorCapture,
      pageTransition,
      onPushPasswordDialogEnterBtn,
      onPushLogout,
      onPushLogOutConfirmationDialogEnterBtn,
    };
  },
};
</script>

<style>
html {
  touch-action: manipulation;
  overscroll-behavior-y: contain;
}
body {
  box-sizing: border-box;
  -webkit-touch-callout: none;
}
</style>

import { chargeAutoRegistType, deliveryCompany } from './appCode';
import { EnvConfig } from './env-config';

export const CommonSettings = {
  TABLE_CARD_COLOR: {
    DEFAULT: 'white',
    OVER_TWO_HOUR: 'text-red',
    OVER_ONE_HOUR_THIRTY_MINUTES: 'text-blue',
    OVER_ONE_HOUR: 'text-blue',
  },
  STORAGE: {
    MENU_IMAGES: 'menuimages',
    OTHER_IMAGES: 'otherimages',
    STAFF_IMAGE: 'staffimage',
    MEDIA_IMAGES: 'mediaimages',
    BG_IMAGE_ANYTIME: 'bgImageAnytime',
    HERO_IMAGE_ANYTIME: 'heroImageAnytime',
    BRAND_LOGO_IMAGE: 'brandLogoImage',
    MEMBERSHIP_LOGO_IMAGE: 'membershipLogoImage',
    STAMP_CARD_BACKGROUND_IMAGE: 'stampCardBackgroundImage',
  }, // StorageのMenu
  WEB_STORAGE_KEY: {
    CALENDAR_MODE_TABLE_LIST: 'calendarModeTableList',
    SOUND_TYPE_ORDER_TICKET: 'orderTicketSoundType',
    SOUND_MANUAL_STOP_ORDER_TICKET: 'orderTicketSoundManualStop',
    MESSAGE_NOTIFICATION_SOUND: 'messageNotificationSound',
    PERMISSION: 'permission',
    ON_BOARD_STATUS: 'onBoardStatus',
    WORKING_MODE_TABLE_LIST: 'workingModeTableList',
    TRAINING_MODE_TABLE_LIST: 'trainingModeTableList',
    CART_ITEMS: 'cartItems',
    CART_ITEMS_EC: 'cartItemsEc',
    CART_ITEMS_EXPIRED: 'cartItemsExpired',
    USER_ORDER_RECEIVE_DATE: 'userOrderReceiveDate',
    GUEST_DATA: 'guestData',
    USER_INFO_DETAIL: 'userInfoDetail',
    SELECT_LANGUAGE: 'selectLanguage',
    SELECT_STAFF: 'selectStaff',
    SMS_AUTH_PHONE: 'smsAuthPhone',
    SMS_AUTH_EXPIRE_TIME: 'smsAuthExpireTime',
    MESSAGING_TOKEN: 'messagingToken',
    MESSAGING_TOKEN_EXPIRED: 'messagingTokenExpired',
    STORE_ID: 'storeId',
    HEADQUARTER_STORE_ID: 'headquarterStoreId',
    HEADQUARTER_PASSWORD: 'headquarterPassword',
  },
  MESSAGE_BIND_KEY: {
    USER_NAME: '{CustomerName}',
    WAITING_NUMBER: '{WaitingNumber}',
  },
  FILE_NAME: {
    MESSAGE_SOUND_1: 'message1.mp3',
    MESSAGE_SOUND_2: 'message2.mp3',
    MESSAGE_SOUND_NONE: 'messagenone',
    KITCHEN_SOUND_1: 'kitchen1.mp3',
    KITCHEN_SOUND_2: 'kitchen2.mp3',
    KITCHEN_SOUND_3: 'kitchen3.mp3',
    KITCHEN_SOUND_4: 'kitchen4.mp3',
    KITCHEN_SOUND_5: 'kitchen5.mp3',
    KITCHEN_SOUND_6: 'kitchen6.mp3',
    SILENT: 'silent.mp3',
  },
  IMAGE_PATH: 'img/',
  EXTERNAL_LINK: {
    TERMS: 'https://bit.ly/3JbMQfQ',
    PRIVACY: 'https://bit.ly/3qCVkX0',
    APP_TERM: 'https://bit.ly/3ygKyd0',
  },
  TAX_RATE: 0.1, // 消費税率
  REDUCED_TAX_RATE: 0.08, // 軽減税率
  SUPPORT_IMAGE_FILE_EXTENSIONS: ['png', 'jpeg', 'jpg'],
  PAYMENT_METHODS: [
    { id: '現金', name: '現金' },
    { id: 'クレジット(店頭)', name: 'クレジット(店頭)' },
    { id: 'PayPay', name: 'PayPay' },
    { id: 'QR', name: 'QR' },
    { id: 'その他', name: 'その他' },
  ],
  ORDER_STATUS: {
    inPayment: '未決済',
    pending: '未入金',
    received: '受注',
    shipping: '発送準備',
    done: '発送済',
    cancel: 'キャンセル',
    refund: '返金済み',
  },
  CHARGE_AUTO_REGIST_TYPE: [
    { id: chargeAutoRegistType.guests, name: '客数連動' },
    { id: chargeAutoRegistType.table, name: '会計単位' },
  ],
  ORDER_TYPE: { order: '注文', lineOrder: 'モバイルオーダー', cancel: '取消', change: '数量変更' },
  ORDER_TICKET_SORT_TYPES: ['注文時間順', '注文時間・商品順', '受取時間順', '注文番号順'],
  PRINTER_BUZZER_SOUND: [
    { name: 'パターンA', value: 'pattern_a' },
    { name: 'パターンB', value: 'pattern_b' },
    { name: 'パターンC', value: 'pattern_c' },
    { name: 'パターンD', value: 'pattern_d' },
    { name: 'パターンE', value: 'pattern_e' },
  ],
  // エントリーフォーム
  SUBSCRIPTION_PAYMENT_METHODS: {
    creditCard: { id: 'creditCard', name: 'クレジットカード', fee: 0 },
    bankTransfer: { id: 'bankTransfer', name: '銀行振込', fee: 0 },
    // alreadyContracted: { id: 'alreadyContracted', name: '既に他店舗で注文くんを契約している', fee: 0 },
    other: { id: 'other', name: 'その他', fee: 0 },
  },
  LINE_OFFICIAL_ACCOUNT_CREATE_MENU: {
    alreadyHave: { id: 'alreadyHave', name: '持っている', fee: 0 },
    createBySelf: { id: 'createBySelf', name: '自分で作成する', fee: 0 },
    createSetting: { id: 'createSetting', name: '作成・諸設定を依頼する', fee: 15000 },
  },
  PICKUP_PLACE_OPTION: {
    monthly: { id: 'monthly', name: '月額', fee: 3000 },
    yearly: { id: 'yearly', name: '年額', fee: 36000 },
  },
  POS_SERVICES: {
    none: { id: 'none', name: '利用しない', fee: 0 },
    blayn: { id: 'blayn', name: 'ブレインレジ', fee: 0 },
    smaregi: { id: 'smaregi', name: 'スマレジ', fee: 0 },
  },
  PLANS: {
    none: {
      id: 'none',
      appName: '',
      name: '申し込まない',
      price: 0,
      initialPrice: 0,
      discription: '',
    },
    eatinChumonKunModuleChannel: {
      id: 'eatinChumonKunModuleChannel',
      appName: '注文くん',
      name: 'for マーケットプレイス',
      price: 9800,
      initialPrice: 0,
      discription: '',
      trialPeriod: 30,
      accountType: 'module_channel',
    },
    eatinChumonKunStandard: {
      id: 'eatinChumonKunStandard',
      appName: '注文くん',
      name: '店内モバイルオーダー専用',
      price: 9800,
      initialPrice: 90000,
      discription: '店内モバイルオーダーがご利用いただけるプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    eatinChumonKunWaiter: {
      id: 'eatinChumonKunWaiter',
      appName: '注文くん',
      name: 'for Waiterプラン',
      price: 10000,
      initialPrice: 90000,
      discription: 'スマレジWaiterと連携してモバイルオーダーをご利用いただけるプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    eatinChumonKun: {
      id: 'eatinChumonKun',
      appName: '注文くん',
      name: 'プラスプラン',
      price: 14800,
      initialPrice: 90000,
      discription: 'LINEミニアプリ、固定QRコード、POS連携などモバイルオーダーをフルに活用いただけるプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },

    takeoutChumonKunPlus: {
      id: 'takeoutChumonKunPlus',
      appName: '注文くん',
      name: 'フードビジネスプラン',
      price: 12800,
      initialPrice: 0,
      discription:
        '店内飲食や販売方式ごとの税率調整にも対応した、フードビジネスでモバイルオーダーをフル活用いただけるプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    takeoutChumonKun: {
      id: 'takeoutChumonKun',
      appName: '注文くん',
      name: '店外モバイルオーダー専用',
      price: 9800,
      initialPrice: 0,
      discription: '事前決済や受取日の指定などモバイルオーダーの標準的な機能を利用できるプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    takeoutChumonKunModuleChannel: {
      id: 'takeoutChumonKunModuleChannel',
      appName: '注文くん',
      name: 'ライトプラン',
      price: 6000,
      initialPrice: 0,
      discription: '',
      trialPeriod: 14,
      accountType: 'module_channel',
    },
    waitingMachiko: {
      id: 'waitingMachiko',
      appName: '順番待ち子ちゃん',
      name: 'スタンダードプラン',
      price: 7800,
      initialPrice: 0,
      discription: '通知機能などLINEミニアプリを活用した順番待ちをご利用いただけるプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    waitingMachikoPro: {
      id: 'waitingMachikoPro',
      appName: '順番待ち子ちゃん',
      name: 'Proプラン',
      price: 9800,
      initialPrice: 0,
      discription:
        'LINEミニアプリに加えてタブレットとプリンターを使ったオーソドックスな順番待ちにも対応し、本格的な順番待ちサービスをご利用いただけるプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    membership: {
      id: 'membership',
      appName: '常連くるみちゃん',
      name: 'スタンダードプラン',
      price: 8800,
      initialPrice: 0,
      discription:
        'LINEミニアプリのデジタル会員証、セグメント配信、スタンプカード、スマレジ連携など標準的なデジタル会員証の機能を備えたプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    ecKun: {
      id: 'ecKun',
      appName: '通販くん',
      name: '通販くんプラン',
      price: 9000,
      initialPrice: 0,
      discription: '',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    monsterPack: {
      id: 'monsterPack',
      appName: 'モンスターパック',
      name: 'パーフェクトパック',
      price: 29800,
      initialPrice: 90000,
      discription:
        'モバイルオーダー、順番待ち、デジタル会員証の全てのサービスがご利用でき、店舗のDXを強力にプッシュするパックプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    boostPack: {
      id: 'boostPack',
      appName: 'モンスターパック',
      name: 'ブーストパック',
      price: 18000,
      initialPrice: 0,
      discription:
        'テイクアウトモバイルオーダー、デジタル会員証のサービスがご利用でき、店舗のDXを強力にプッシュするパックプランです',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
    chumonKun: {
      id: 'chumonKun',
      appName: '注文くん',
      name: 'プラスプラン',
      price: 15000,
      initialPrice: 90000,
      discription:
        '注文くんの全ての機能がご利用いただけます。モバイルオーダーから常連獲得まで、店舗のDXを強力にプッシュします',
      trialPeriod: 30,
      accountType: 'messaging_api',
    },
  },
  PREFECTURES: [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ],
  DELIVERY_TIME: {
    [deliveryCompany.yamato]: { 1: '希望なし', 2: '8-12', 3: '14-16', 4: '16-18', 5: '18-20', 6: '19-21' },
    [deliveryCompany.sagawa]: { 1: '希望なし', 2: '8-12', 3: '14-16', 4: '16-18', 5: '18-20', 6: '19-21' },
    [deliveryCompany.seino]: { 1: '希望なし', 2: '8-12', 3: '14-16', 4: '16-18', 5: '18-20', 6: '19-21' },
    [deliveryCompany.jp]: { 1: '希望なし', 2: '8-12', 3: '14-16', 4: '16-18', 5: '18-20', 6: '19-21' },
    [deliveryCompany.mail]: { 1: '希望なし', 2: '8-12', 3: '14-16', 4: '16-18', 5: '18-20', 6: '19-21' },
  },
  MENU_CATEGORY_OTHER: {
    REORDER: 'reorder',
    LAST_VISIT: 'lastVisit',
  },
  // デモ用アカウント
  DEMO_ACCOUNT: ['5LgobI2JaKWvkvVXBh0S3TfpSSu2'],
  // カスタマイズをしたUID
  CUSTMIZED_CONTRACT_ID: {
    SUKESAN: EnvConfig.app.SUKESAN_UID,
    EP_TEST: EnvConfig.app.EP_TEST_UID,
  },
};

export const CustmizedContractIds: CustmizedContractId = CommonSettings.CUSTMIZED_CONTRACT_ID;

export interface CustmizedContractId {
  SUKESAN?: string;
  EP_TEST?: string;
}

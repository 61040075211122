import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_bar = _resolveComponent("menu-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_error_dialog = _resolveComponent("error-dialog")!
  const _component_loading_dialog = _resolveComponent("loading-dialog")!
  const _component_password_dialog = _resolveComponent("password-dialog")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      ($setup.alreadyLogin && $setup.alreadyStoreLogin)
        ? (_openBlock(), _createBlock(_component_menu_bar, {
            key: 0,
            pageTitle: $setup.pageTitle,
            pageOrder: $setup.pageOrder,
            appVersion: $setup.appVersion,
            onOnPushItem: $setup.pageTransition,
            onOnPushLogout: $setup.onPushLogout,
            onError: $setup.errorCapture
          }, null, 8, ["pageTitle", "pageOrder", "appVersion", "onOnPushItem", "onOnPushLogout", "onError"]))
        : _createCommentVNode("", true),
      ($setup.alreadyLogin)
        ? (_openBlock(), _createBlock(_component_v_main, {
            key: 1,
            style: _normalizeStyle({ background: _ctx.$vuetify.theme.themes.light.variables.background })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, { onError: $setup.errorCapture }, null, 8, ["onError"])
            ]),
            _: 1
          }, 8, ["style"]))
        : _createCommentVNode("", true),
      (!$setup.alreadyLogin)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 2,
            onError: $setup.errorCapture
          }, null, 8, ["onError"]))
        : _createCommentVNode("", true),
      _createVNode(_component_error_dialog, { ref: "errorDialog" }, null, 512),
      _createVNode(_component_loading_dialog, {
        ref: "loadingDialog",
        display: $setup.isLoading,
        message: $setup.loadingMessage
      }, null, 8, ["display", "message"]),
      _createVNode(_component_password_dialog, {
        ref: "passwordDialog",
        message: 'この画面へ遷移するためにはパスワードの入力が必要です。',
        onOnPushEnterBtn: $setup.onPushPasswordDialogEnterBtn
      }, null, 8, ["onOnPushEnterBtn"]),
      _createVNode(_component_confirmation_dialog, {
        ref: "logoutConfirmationDialog",
        title: 'ログアウト',
        message: 'ログアウトしてもよろしいですか?',
        onOnPushEnterBtn: $setup.onPushLogOutConfirmationDialogEnterBtn
      }, null, 8, ["onOnPushEnterBtn"])
    ]),
    _: 1
  }))
}
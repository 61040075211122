import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
// import { createStore } from 'vuex';
import '@/registerServiceWorker';
import vuetify from './plugins/vuetify';
import VueGtag from 'vue-gtag';

import * as Router from '@/router/index';

import App from './App.vue';
import { useUsersStore } from '@/stores/users';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase/index';
// import { updateFcmToken } from './firebase/functions';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { CommonSettings } from './common/common-settings';
import { createPinia } from 'pinia';
// import * as Sentry from '@sentry/vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: Router.routes,
});

const app = createApp(App);
app.use(createPinia());
app.component(VueQrcode.name, VueQrcode);

console.log(process.env.VUE_APP_MODE);

if (process.env.VUE_APP_MODE === 'production' || process.env.VUE_APP_MODE === 'staging') {
  // console.log('log init');

  // Sentry.init({
  //   app,
  //   dsn: process.env.VUE_APP_SENTRY_DSN,
  //   integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  //   // Tracing
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   environment: process.env.VUE_APP_MODE,
  // });

  if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
    app.use(
      VueGtag,
      {
        config: {
          id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
        },
      },
      router
    );
  }
}

/**
 * ログインしているかどうか
 */
router.beforeEach((to) => {
  const requiresAuth: boolean = to.matched.some((record) => record.meta.requiresAuth);

  // 認証が必要ないルートの場合は何もしない
  if (!requiresAuth) {
    // onAuthStateChanged(auth, (user) => {
    //   // 強制ログアウトする画面
    //   if (user && to.meta.forceLogout) {
    //     console.log('logout.');
    //     userModule.logout(false);
    //   }
    //   // Storeのuser情報を削除
    //   userModule.resetUser();
    // });
    console.log('not require auth. ');
    return;
  }

  // 認証が必要なルートの場合はログインされているかどうか確認し、されていなければログインページにリダイレクト
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // ここでunsubscribeしないと、onAuthStateChangedの処理がスタックされるので重要
      unsubscribe();

      // ログインしていない場合はログインページにリダイレクト
      if (!user) {
        return resolve({
          path: '/login',
          query: {
            redirect: to.fullPath,
          },
        });
      }

      const userStore = useUsersStore();

      // AccountSetting関連の情報初期化
      await userStore.initAccount({ user: user });

      // updateFcmToken(user.uid); // TODO:要対応 プッシュ通知は一時停止するかどうか

      const is2ndGenData = userStore.getAccountSetting?.is2ndGenData ?? false;
      const storeId = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.STORE_ID);

      // 旧データの時に店舗選択画面がリクエストされたらホーム画面にリダイレクト
      if (!is2ndGenData && to.path === '/select-store') {
        return resolve({ path: '/home' });
      }

      // 新データの時に店舗選択画面以外のリクエスト時に店舗IDが無い場合は店舗選択画面にリダイレクト
      if (is2ndGenData && to.path !== '/select-store' && !storeId) {
        return resolve({ path: '/select-store' });
      }

      // settings関連の情報初期化
      if (to.path !== '/select-store') {
        const isLoginable = await userStore.initStore({ user: user });

        // ログインできない場合はログインページにリダイレクト
        if (!isLoginable) {
          return resolve({
            path: '/login',
            query: {
              redirect: to.fullPath,
            },
          });
        }
      }

      resolve();
    });
  });
});

// ページタイトルを設定
router.afterEach((to) => {
  if (to.meta?.title) {
    console.log(to.path);
    document.title = to.meta.title as string;
  }
  // if (to.meta?.viewportFit) {
  //   document
  //     .querySelector("meta[name='viewport']")
  //     // ?.setAttribute('content', 'width=device-width,initial-scale=1.0,viewport-fit=cover,user-scalable=no');
  //     ?.setAttribute('content', 'viewport-fit=cover, width=device-width, initial-scale=1, user-scalable=no');
  // } else {
  //   document
  //     .querySelector("meta[name='viewport']")
  //     ?.setAttribute('content', 'width=device-width,initial-scale=1.0,user-scalable=no');
  // }
});

app.use(vuetify).use(router).mount('#app');
